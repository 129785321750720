/* eslint-disable */
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

import {
    IGroup,
    IOrganizationDto,
    IOrganizationConfigDto,
} from "@core/interfaces/dto/user-profile-dto.interface";
import { IOrganizationPermission } from "@core/interfaces/organization-permission.interface";
import { GlobalCacheService } from "@core/services/global-cache.service";
import {
    IOrganizationDataAvailabilityFlags,
    OrganizationDataAvailabilityStorageService,
} from "@core/services/storages/organization-data-availability-storage.service";
import { PermissionsStorageService } from "./permissions-storage.service";

@Injectable({
    providedIn: "root",
})
export class OrganizationStorageService {
    private data: BehaviorSubject<IOrganizationDto[]> = new BehaviorSubject(null);

    // Active organization's data
    private _activeOrganization: IOrganizationDto | null = null;

    // Active group's data
    private _activeGroup: IGroup | null = null;

    // all organizations
    private organizations: IOrganizationDto[] = [];

    /**
     * Initialization
     */
    constructor(
        private permissions: PermissionsStorageService,
        private dataAvailability: OrganizationDataAvailabilityStorageService
    ) {}

    /** ************************************************************************
     All organizations
     ************************************************************************* */
    get all(): IOrganizationDto[] {
        return this.organizations;
    }

    /** ************************************************************************
     Configuration & Flags
     ************************************************************************* */
    get config(): IOrganizationConfigDto {
        return this.dataAvailability.config;
    }

    get flags(): IOrganizationDataAvailabilityFlags {
        return this.dataAvailability.flags;
    }

    /** ************************************************************************
     Active organization & group
     ************************************************************************* */
    get activeOrganization(): IOrganizationDto {
        return this._activeOrganization;
    }

    get activeGroup() {
        return this._activeGroup;
    }

    get hasGroups(): boolean {
        return !!this._activeOrganization?.groups.length;
    }

    get currentCulture(): string {
        return (this._activeGroup ?? this._activeOrganization)?.currentCulture;
    }

    get isAliased(): boolean {
        return this.activeGroup.groupId
            ? this.activeGroup.isAliased
            : this.activeOrganization.isAliased;
    }

    /** ************************************************************************
     DATA
     ************************************************************************* */
    setData(value: IOrganizationDto[]): void {
        this.organizations = value || [];
        // clear all cached data
        GlobalCacheService.clearAll();
        // calculate related properties
        if (value) {
            // find active org and group
            this._activeOrganization = value.find(org => org.active);
            this._activeGroup = this._activeOrganization?.groups.find(grp => grp.active);
        } else {
            this._activeOrganization = null;
            this._activeGroup = null;
        }
        this.data.next(value);
    }

    public all$: Observable<IOrganizationDto[]> = this.data.asObservable();

    /** ************************************************************************
     PERMISSIONS
     ************************************************************************* */
    setPermissions(value: IOrganizationPermission[]): void {
        this.permissions.setData(value);
    }

    public permissions$: Observable<IOrganizationPermission[]> =
        this.permissions.data.asObservable();

    /** ************************************************************************
     DATA AVAILABILITY FLAGS
     ************************************************************************* */
    setFlags(value: IOrganizationConfigDto): void {
        this.dataAvailability.setData(value);
    }

    public flags$: Observable<IOrganizationDataAvailabilityFlags> =
        this.dataAvailability.data.asObservable();

    /** ************************************************************************
     ACTIVE ORGANIZATION USER ID
     ************************************************************************* */

    getUserId(): string {
        return this.activeGroup?.userId ?? this.activeOrganization?.userId;
    }
}
