import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import {
    IBackLink,
    ILastUrl,
    IQuickLink,
    ISetting,
    TimeframeSettings,
} from "@core/interfaces/settings.interface";
import { LocalStorageService } from "@core/services/local-storage.service";
import { UtilityService } from "@shared/services/utility.service";

const STORAGE_KEY = "edge-settings";

const DEFAULT_TIMEFRAME_SETTINGS: TimeframeSettings = {
    settings: null,
    startDate: null,
    endDate: null,
    isDefault: true,
};

const DEFAULT_SETTING: ISetting = {
    userName: null,
    isCurrent: null,
    sideBarCollapsed: false,
    lastUrl: null,
    timeframe: DEFAULT_TIMEFRAME_SETTINGS,
    rememberMe: null,
    urlPNCBank: null,
    pageTitle: "",
    back: null,
    quickLinks: [],
    isPublic: false,
};

@Injectable({
    providedIn: "root",
})
export class SettingStorageService {
    public settings$: Observable<ISetting[]>;
    private settingsData$: BehaviorSubject<ISetting[]> = new BehaviorSubject([]);

    /**
     * We only need unAuthUserSetting if the user is not authorised and we go to UnAuthLayout.
     * Value are used to store the current settings for the user without saving them to the memory.
     */
    private unAuthUserSetting = UtilityService.deepClone({...DEFAULT_SETTING, isCurrent: true});

    constructor(private localStorageService: LocalStorageService) {
        this.settings$ = this.settingsData$.asObservable();
        this.initSettingData();
    }

    /**
     * Init user in first login
     */
    public initUserSetting(userName: string): void {
        // todo: refactor
        if (this.hasUserSetting(userName)) {
            this.updateStorage(userName, {
                ...DEFAULT_SETTING,
                ...this.getUserSetting(userName),
                userName,
            });
        } else {
            this.addStorage({
                ...DEFAULT_SETTING,
                userName,
            });
        }

        this.setUserCurrent(userName);
    }

    /**
     * Side Bar collapsed | expanded state
     */
    public isSidebarCollapsed(): Observable<boolean> {
        return this.getCurrentUserSetting$().pipe(map((item: ISetting) => !!item?.sideBarCollapsed));
    }

    /**
     * Redirection URL for PNCBank organization | exception
     */
    public getPNCBankURL(): Observable<string> {
        return this.getCurrentUserSetting$().pipe(map((item: ISetting) => item?.urlPNCBank));
    }

    /**
     * Page Title
     */
    public pageTitle(): Observable<string> {
        return this.getCurrentUserSetting$().pipe(map((item: ISetting) => item?.pageTitle));
    }

    /**
     * Back link button
     */
    public backLink(): Observable<IBackLink | null> {
        return this.getCurrentUserSetting$().pipe(map((item: ISetting) => item?.back));
    }

    /**
     * Top bar quick links
     */
    public quickLinks(): Observable<IQuickLink[] | null> {
        return this.getCurrentUserSetting$().pipe(map((item: ISetting) => item?.quickLinks));
    }

    /**
     * Public page
     *
     * On public pages sidebar and some items in topbar
     * (organization DDL, open tickets and active alarms) are hidden
     */
    public isPublic(): Observable<boolean | null> {
        return this.getCurrentUserSetting$().pipe(map((item: ISetting) => item?.isPublic));
    }

    /**
     * Setting data
     */
    public updateSetting(setting: Partial<ISetting>): void {
        const currentUserName = this.getUserName();
        const currentUserSetting = this.getCurrentUserSetting();
        this.updateStorage(currentUserName, {
            ...currentUserSetting,
            ...setting,
        });
    }

    public getSetting(): ISetting {
        return this.getCurrentUserSetting();
    }

    public checkIfRememberMeOn(): boolean {
        return this.getCurrentUserSetting()?.rememberMe;
    }

    /**
     * Url for redirect (last state)
     */
    public hasRedirectUrl(): boolean {
        let defaultUser = this.getDefaultUserSetting();
        return !!this.getSetting().lastUrl || !!defaultUser.lastUrl;
    }

    public getRedirectUrl(): ILastUrl | null {
        let defaultUser = this.getDefaultUserSetting();
        return this.getSetting().lastUrl || defaultUser.lastUrl;
    }

    // Todo: use in IDLE
    public setRedirectUrl(lastUrl: ILastUrl): void {
        this.updateSetting({
            lastUrl,
        });
    }

    public cleanRedirectUrl(): void {
        this.updateSetting({
            lastUrl: null,
        });
    }

    /**
     * User Name
     */
    public getUserName(): string {
        return this.getCurrentUserSetting()?.userName;
    }

    public setUserName(userName: string): void {
        this.setUserCurrent(userName);
    }

    public getCurrentUserSetting$(): Observable<ISetting> {
        return this.settingsData$.pipe(
            map((settings: ISetting[]): ISetting => {
                return settings.find(setting => setting.isCurrent);
            })
        );
    }

    /**
     * Private
     *
     * Init data from local storage
     */
    private initSettingData(): void {
        const storageData = this.localStorageService.getItem(STORAGE_KEY);
        if (storageData) {
            this.settingsData$.next(storageData as any);
        }
    }

    private hasUserSetting(userName: string): boolean {
        const settings = this.settingsData$.getValue();
        return Boolean(settings.find(setting => setting.userName === userName));
    }

    private getCurrentUserSetting(): ISetting {
        const settings = this.settingsData$.getValue();
        return settings.find(setting => setting.isCurrent);
    }

    private getUserSetting(userName: string): ISetting {
        const settings = this.settingsData$.getValue();
        return settings.find(setting => setting.userName === userName);
    }

    private getDefaultUserSetting(): ISetting {
        const settings = this.settingsData$.getValue();
        return settings.find(setting => setting.userName === null);
    }

    private setUserCurrent(userName: string): void {
        this.resetIsCurrentFlag();
        this.updateStorage(userName, {
            isCurrent: true,
        });
    }

    // Reset IsCurrent flag in all users
    private resetIsCurrentFlag(): void {
        let usersSettings = [...this.settingsData$.getValue()];
        usersSettings = usersSettings.map(setting => {
            return {
                ...setting,
                isCurrent: false,
            };
        });

        this.settingsData$.next(usersSettings);
        this.localStorageService.setItem(STORAGE_KEY, usersSettings);
    }

    private addStorage(settings) {
        const usersSetting = [...this.settingsData$.getValue()];
        // Todo: Check exist
        usersSetting.push(settings);
        this.settingsData$.next(usersSetting);
        this.localStorageService.setItem(STORAGE_KEY, usersSetting);
    }

    private updateStorage(userName: string, settings) {
        if (userName) {
            const usersSetting = [...this.settingsData$.getValue()];
            const storageData = usersSetting.map(userSetting => {
                if (userSetting.userName === userName) {
                    return {
                        ...userSetting,
                        ...settings,
                    };
                }
                return userSetting;
            });

            this.settingsData$.next(storageData);
            this.localStorageService.setItem(STORAGE_KEY, storageData);
        } else {
            this.unAuthUserSetting = { ...this.unAuthUserSetting, ...settings };
            this.settingsData$.next([this.unAuthUserSetting]);
            this.localStorageService.setItem(STORAGE_KEY, [this.unAuthUserSetting]);
        }
    }

    // TODO: Uncomment when you need this method
    // private getStorageProperty4CurrentUser(propertyName: string): any {
    //     return this.getCurrentUserSetting()[propertyName];
    // }

    // TODO: Uncomment when you need this method
    // private updateStorageProperty4CurrentUser(propertyName: string, propertyValue: any): void {
    //     const setting = this.getCurrentUserSetting();
    //     this.updateStorage(setting.userName, {
    //         ...setting,
    //         [propertyName]: propertyValue,
    //     });
    // }
}
