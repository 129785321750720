import { Injectable } from "@angular/core";
import extend from "just-extend";
import clone from "just-clone";

@Injectable({
    providedIn: "root",
})
export class UtilityService {
    public static isIEBrowser(): boolean {
        return (
            window.navigator.userAgent.indexOf("MSIE ") > -1 ||
            window.navigator.userAgent.indexOf("Trident/") > -1
        );
    }

    public static getISOStringInLocalFormat(date: Date, hours: string, minutes: string): string {
        const correctHours = hours && hours.toString().length === 1 ? `0${hours}` : hours;
        const correctMinutes = hours && minutes.toString().length === 1 ? `0${minutes}` : minutes;
        return `${date.toISOString().split("T")[0]}T${correctHours}:${correctMinutes}:00.000`;
    }

    /** Deep copies objects, arrays, maps and sets */
    public static deepClone<T extends object>(data: T): T {
        return clone(data);
    }

    /**
     * Deep merges 1 or multiple sources into target
     * @returns Reference to target
     */
    public static deepMerge(target: object, ...sources: any[]): object {
        return extend(true, target, ...sources);
    }

    public static combineDateAndTime(date: Date, time: Date): Date {
        let resultDate = null;

        if (date) {
            resultDate = new Date(date.getTime());

            if (time) {
                resultDate.setHours(time.getHours());
                resultDate.setMinutes(time.getMinutes());
                resultDate.setSeconds(time.getSeconds());
            }
        }

        return resultDate;
    }

    public static setStartDate(date: Date): Date {
        if (!date) {
            return null;
        }

        const newDate = new Date(date);
        newDate.setHours(0, 0, 0, 0);
        return newDate;
    }

    public static setEndDate(date: Date): Date {
        if (!date) {
            return null;
        }

        const newDate = new Date(date);
        newDate.setHours(23, 59, 59, 999);
        return newDate;
    }

    public static removeNullObjectFields(object: object): object {
        // eslint-disable-next-line no-return-assign, no-param-reassign
        return Object.entries(object).reduce((a, [k, v]) => (v === null ? a : ((a[k] = v), a)), {});
    }

    public static openSeparateWindow(url: string): void {
        window.open(url, "window", "width=1000,height=700");
    }

    public static daylightSavingTime(date: Date): Date {

        if(!date) {
            return null;
        }

        // Get the timezone offset for the date in January (no DST in most places)
        const jan = new Date(date.getFullYear(), 0, 1);
        // Get the timezone offset for the date in July (DST in most places)
        const jul = new Date(date.getFullYear(), 6, 1);

        // Compare the current timezone offset to the January and July offsets
        const isDST =
            Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset()) !== date.getTimezoneOffset();

        // Adjust the time if DST is active
        if (isDST) {
            // Add 1 hour to the time to account for DST
            date.setHours(date.getHours() + 1);
        }

        return date;
    }
}
