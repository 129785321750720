import { NgModule, inject } from "@angular/core";
import { Router, RouterModule } from "@angular/router";
import { PERMISSIONS_CUSTOM } from "@core/constants/permission-custom.constant";
import { PERMISSIONS } from "@core/constants/permission.constant";
import { LayoutType } from "@core/enums/layout-type.enum";
import { OrganizationDataAvailabilityFlags } from "@core/enums/organization.enum";
import { TimeframeSettingsOption } from "@core/enums/timeframe.enum";
import { AuthGuard } from "@core/guards/auth.guard";
import { AvailabilityGuard } from "@core/guards/availability.guard";
import { UnAuthGuard } from "@core/guards/un-auth.guard";
import { WizardGuard } from "@core/guards/wizard.guard";
import { EdgeRoutes } from "@core/interfaces/routes.interface";
import { OrganizationResolver } from "@core/resolvers/organization.resolver";
import { AuthLayoutShellComponent } from "./auth/layouts/auth-layout/containers/auth-layout-shell/auth-layout-shell.component";
import { PortalLayoutShellComponent } from "./portal/portal-layout/containers/portal-layout-shell/portal-layout-shell.component";
import { oldRoutesRedirection } from "./old-routes-redirection";
import { ForgotPasswordResolver } from "src/app/portal/forgot-password/forgot-password.resolver";
import { SamlGuard } from "@core/guards/saml.guard";
import { GeneralSettingsResolver } from "@core/resolvers/general-settings.resolver";
import { SettingStorageService } from "@core/services/storages/setting-storage.service";

const routes: EdgeRoutes = [
    { path: "", pathMatch: "full", redirectTo: "/home" },
    oldRoutesRedirection, // TODO: Remove this after a few month in production (see inner details)
    {
        path: "",
        component: PortalLayoutShellComponent,
        resolve: {
            organization: OrganizationResolver,
            generalSettings: GeneralSettingsResolver
        },
        data: {
            defaultTimeframeOption: TimeframeSettingsOption.Last30Days,
            defaultTimeframeOptions: [
                TimeframeSettingsOption.Today,
                TimeframeSettingsOption.Yesterday,
                TimeframeSettingsOption.Last7Days,
                TimeframeSettingsOption.Last30Days,
                TimeframeSettingsOption.ThisMonth,
                TimeframeSettingsOption.LastMonth,
                TimeframeSettingsOption.Custom,
            ],
        },
        children: [
            {
                // Portal
                path: "",
                canMatch: [AuthGuard],
                data: {
                    layout: LayoutType.PortalLayout,
                },
                children: [
                    {
                        path: "home",
                        canMatch: [AvailabilityGuard],
                        data: {
                            isNewsSectionShown: true,
                            availability: {
                                permissionCode: PERMISSIONS.HomePage,
                            },
                            defaultTimeframeOption: TimeframeSettingsOption.Last30Days,
                        },
                        loadChildren: () =>
                            import("./portal/home/home.module").then(
                                ({ HomeModule }) => HomeModule
                            ),
                    },
                    {
                        path: "live-status",
                        canMatch: [AvailabilityGuard],
                        data: {
                            availability: {
                                permissionCode: PERMISSIONS.LiveStatusPage,
                            },
                        },
                        loadChildren: () =>
                            import("./portal/live-status/live-status.module").then(
                                ({ LiveStatusModule }) => LiveStatusModule
                            ),
                    },
                    {
                        path: "dashboard",
                        canMatch: [AvailabilityGuard],
                        data: {
                            availability: {
                                permissionCode: PERMISSIONS.DashboardPage,
                            },
                            defaultTimeframeOption: TimeframeSettingsOption.Today,
                        },
                        loadChildren: () =>
                            import("./portal/dashboard/dashboard.module").then(
                                ({ DashboardModule }) => DashboardModule
                            ),
                    },
                    {
                        path: "colocation",
                        canMatch: [AvailabilityGuard],
                        data: {
                            availability: {
                                permissionCode: PERMISSIONS.ColocationPage,
                            },
                        },
                        loadChildren: () =>
                            import("./portal/colocation/colocation.module").then(
                                ({ ColocationModule }) => ColocationModule
                            ),
                    },
                    {
                        path: "network-data",
                        canMatch: [AvailabilityGuard],
                        data: {
                            availability: {
                                permissionCode: PERMISSIONS.NetworkDataPage,
                            },
                        },
                        loadChildren: () =>
                            import("./portal/network-n-internet/network-n-internet.module").then(
                                ({ NetworkNInternetModule }) => NetworkNInternetModule
                            ),
                    },
                    {
                        path: "datacenter-access",
                        canMatch: [AvailabilityGuard],
                        data: {
                            availability: {
                                permissionCode: PERMISSIONS.DataCenterPhysicalAccessPage,
                                organizationFlag:
                                    OrganizationDataAvailabilityFlags.DatacenterPhysicalAccess,
                            },
                        },
                        loadChildren: () =>
                            import(
                                "./portal/datacenter-physical-access/datacenter-physical-access.module"
                            ).then(
                                ({ DatacenterPhysicalAccessModule }) =>
                                    DatacenterPhysicalAccessModule
                            ),
                    },
                    {
                        path: "managed-systems",
                        canMatch: [AvailabilityGuard],
                        data: {
                            availability: {
                                permissionCustom: PERMISSIONS_CUSTOM.ManagedSystems,
                                organizationFlag: OrganizationDataAvailabilityFlags.ManagedSystems,
                            },
                        },
                        loadChildren: () =>
                            import("./portal/managed-systems/managed-systems.module").then(
                                ({ ManagedSystemsModule }) => ManagedSystemsModule
                            ),
                    },
                    {
                        path: "managed-services",
                        canMatch: [AvailabilityGuard],
                        data: {
                            availability: {
                                permissionCode: PERMISSIONS.ServicesPage,
                            },
                        },
                        loadChildren: () =>
                            import("./portal/managed-services/managed-services.module").then(
                                ({ ManagedServicesModule }) => ManagedServicesModule
                            ),
                    },
                    {
                        path: "support",
                        canMatch: [AvailabilityGuard],
                        data: {
                            availability: {
                                permissionCode: PERMISSIONS.SupportPage,
                            },
                        },
                        loadChildren: () =>
                            import("./portal/support/support.module").then(
                                ({ SupportModule }) => SupportModule
                            ),
                    },
                    {
                        path: "compliance",
                        canMatch: [AvailabilityGuard],
                        data: {
                            availability: {
                                permissionCode: PERMISSIONS.CompliancePage,
                            },
                        },
                        loadChildren: () =>
                            import("./portal/compliance/compliance.module").then(
                                ({ ComplianceModule }) => ComplianceModule
                            ),
                    },
                    {
                        path: "security",
                        canMatch: [AvailabilityGuard],
                        data: {
                            stateName: "Security",
                            availability: {
                                permissionCode: PERMISSIONS.SecurityPage,
                            },
                            defaultTimeframeOption: TimeframeSettingsOption.Last1hour,
                            defaultTimeframeOptions: [
                                TimeframeSettingsOption.Last1hour,
                                TimeframeSettingsOption.Last6hours,
                                TimeframeSettingsOption.Last24hours,
                                TimeframeSettingsOption.Last48hours,
                            ],
                        },
                        loadChildren: () =>
                            import("./portal/security/security.module").then(
                                ({ SecurityModule }) => SecurityModule
                            ),
                    },
                    {
                        path: "analytics",
                        // TODO: Is there any permissions?
                        data: {
                            defaultTimeframeOption: TimeframeSettingsOption.Last30Days,
                            defaultTimeframeOptions: [
                                TimeframeSettingsOption.ThisWeek,
                                TimeframeSettingsOption.LastWeek,
                                TimeframeSettingsOption.ThisMonth,
                                TimeframeSettingsOption.LastMonth,
                                TimeframeSettingsOption.Last30Days,
                                TimeframeSettingsOption.ThisQuarter,
                                TimeframeSettingsOption.LastQuarter,
                                TimeframeSettingsOption.ThisYear,
                                TimeframeSettingsOption.LastYear,
                                TimeframeSettingsOption.Custom,
                            ],
                        },
                        loadChildren: () =>
                            import("./portal/analytics/analytics.module").then(
                                ({ AnalyticsModule }) => AnalyticsModule
                            ),
                    },
                    {
                        path: "my-account",
                        canMatch: [AvailabilityGuard],
                        data: {
                            availability: {
                                permissionCustom: PERMISSIONS_CUSTOM.MyAccount,
                            },
                        },
                        loadChildren: () =>
                            import("./portal/my-account/my-account.module").then(
                                ({ MyAccountModule }) => MyAccountModule
                            ),
                    },
                    {
                        path: "my-profile",
                        canMatch: [AvailabilityGuard],
                        data: {
                            availability: {
                                permissionCode: PERMISSIONS.MyProfilePage,
                            },
                        },
                        loadChildren: () =>
                            import("./portal/my-profile/my-profile.module").then(
                                ({ MyProfileModule }) => MyProfileModule
                            ),
                    },
                    {
                        path: "monitors",
                        canMatch: [AvailabilityGuard],
                        data: {
                            stateName: "Monitors",
                            availability: {
                                permissionCode: PERMISSIONS.MonitorsPage,
                            },
                        },
                        loadChildren: () =>
                            import("./portal/monitors/monitors.module").then(
                                ({ MonitorsModule }) => MonitorsModule
                            ),
                    },
                    {
                        path: "alarms",
                        canMatch: [AvailabilityGuard],
                        data: {
                            availability: {
                                permissionCode: PERMISSIONS.AlarmsPage,
                            },
                        },
                        loadChildren: () =>
                            import("./portal/active-alarms/active-alarms.module").then(
                                ({ ActiveAlarmsModule }) => ActiveAlarmsModule
                            ),
                    },
                    {
                        path: "data-protected",
                        canMatch: [AvailabilityGuard],
                        data: {
                            stateName: "Data Protected",
                            availability: {
                                permissionCode: PERMISSIONS.DataProtectedPage,
                            },
                        },
                        loadChildren: () =>
                            import(
                                "./portal/data-protected-backups/data-protected-backups.module"
                            ).then(({ DataProtectedBackupsModule }) => DataProtectedBackupsModule),
                    },
                    {
                        path: "access-denied",
                        loadChildren: () =>
                            import("./portal/access-denied/access-denied.module").then(
                                ({ AccessDeniedModule }) => AccessDeniedModule
                            ),
                    },
                ],
            },
            {
                // UnAuth Portal
                path: "",
                data: {
                    layout: LayoutType.UnAuthLayout,
                },
                children: [
                    {
                        // Query Params: ticketnumber, supportcallid, rating, personid
                        path: "support-feedback",
                        data: {
                            stateName: "Feedback",
                            isPublic: true,
                            isTimeSettingHidden: true,
                        },
                        loadChildren: () =>
                            import("./portal/feedback/feedback.module").then(
                                ({ FeedbackModule }) => FeedbackModule
                            ),
                    },
                    {
                        path: "invalid-link",
                        data: {
                            stateName: "Invalid Link",
                            isPublic: true,
                            isTimeSettingHidden: true,
                        },
                        loadChildren: () =>
                            import("./portal/invalid-link/invalid-link.module").then(
                                ({ InvalidLinkModule }) => InvalidLinkModule
                            ),
                    },
                    {
                        path: "order",
                        data: {
                            isPublic: true,
                            isTimeSettingHidden: true,
                        },
                        loadChildren: () =>
                            import("./portal/order/order.module").then(
                                ({ OrderModule }) => OrderModule
                            ),
                    },
                    {
                        path: "unsubscribe-monthly-summary-email",
                        data: {
                            stateName: "Monthly Summary Email",
                            isPublic: true,
                        },
                        loadChildren: () =>
                            import(
                                "./portal/unsubscribe-monthly-email/unsubscribe-monthly-email.module"
                            ).then(
                                ({ UnsubscribeMonthlyEmailModule }) => UnsubscribeMonthlyEmailModule
                            ),
                    },
                    {
                        path: "bandwidth-usage",
                        data: {
                            stateName: "Bandwidth Usage",
                            isPublic: true,
                            skipOrganizationResolver: true,
                        },
                        loadChildren: () =>
                            import(
                                "./portal/bandwidth-usage-page/bandwidth-usage-page.module"
                            ).then(({ BandwidthUsagePageModule }) => BandwidthUsagePageModule),
                    },
                    {
                        path: "forgot-your-password",
                        data: {
                            stateName: "Reset Your Password",
                            isPublic: true,
                            isTimeSettingHidden: true,
                        },
                        resolve: {
                            userData: ForgotPasswordResolver
                        },
                        loadChildren: () =>
                            import("./portal/forgot-password/forgot-password.module")
                                .then(({ ForgotPasswordModule }) => ForgotPasswordModule),
                    },
                ],
            },
        ],
    },
    {
        // Auth
        path: "",
        component: AuthLayoutShellComponent,
        children: [
            {
                path: "unsubscribe",
                loadChildren: () =>
                    import("./auth/modules/unsubscribe/unsubscribe.module").then(
                        ({ UnsubscribeModule }) => UnsubscribeModule
                    ),
            },
            {
                // Query Param: defaultStep, backState
                path: "wizard",
                canMatch: [WizardGuard],
                loadChildren: () =>
                    import("./auth/modules/wizard/wizard.module").then(
                        ({ WizardModule }) => WizardModule
                    ),
            },
            {
                path: "setup-account",
                loadChildren: () =>
                    import("./auth/modules/setup-account/setup-account.module").then(
                        ({ SetupAccountModule }) => SetupAccountModule
                    ),
            },
            {
                path: "",
                canMatch: [UnAuthGuard],
                loadChildren: () =>
                    import("./auth/auth.module").then(({ AuthModule }) => AuthModule),
            },
            {
                path: "maintenance",
                loadChildren: () =>
                    import("./auth/modules/maintenance/maintenance.module").then(
                        ({ MaintenanceModule }) => MaintenanceModule
                    ),
            },
        ],
    },

    {
        path: "style-guide",
        loadChildren: () =>
            import("./portal/style-guide/style-guide.module").then(
                ({ StyleGuideModule }) => StyleGuideModule
            ),
    },

    //Redirect user to a third party Web Page
    {
        path: "saml",
        canActivate: [SamlGuard],
        loadComponent: () => null
    },

    // Redirect to Home or Login page, depending on whether user is logged in or not
    {
        path: "**",
        loadComponent: () => null,
        canActivate: [() => {
            const router = inject(Router), isAuthorized = AuthGuard();
            if (isAuthorized) return router.parseUrl("home");
            const settingStorageService = inject(SettingStorageService);
            const window = inject(Window);
            settingStorageService.setRedirectUrl({
                path: window.location.pathname
            });
            return router.parseUrl("login");
        }]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: "ignore" })],
    exports: [RouterModule],
})
export class AppRoutingModule { }
