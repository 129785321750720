import { HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse, HttpEvent } from "@angular/common/http";
import { Router } from "@angular/router";
import { Observable, throwError, catchError } from "rxjs";
import { SettingStorageService } from "@core/services/storages/setting-storage.service";
import { Injectable } from "@angular/core";
import { DialogService } from "@shared/components/dialog/dialog.service";


@Injectable()
export class OrderErrorInterceptor implements HttpInterceptor {
    constructor(private router: Router,
                private settingStorageService: SettingStorageService,
                private dialogService: DialogService){}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>{

        const orderDetailPattern = /^\/?order\/detail\/[^/]+$/;

        if (orderDetailPattern.test(this.router.url)) {
          
          return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
              
              if (error.status === 401) {             
                this.settingStorageService.setRedirectUrl({
                    path: this.router.url
                })
                this.router.navigate(["/login"]);
              } else if (error.status === 400) {
                const authSignerErrorMessage = error.error?.message;
                if (authSignerErrorMessage){
                  this.dialogService.confirm({
                      data: {
                          body: authSignerErrorMessage,
                          title: "Information",
                          onConfirm: () => { this.router.navigate(["/home"]); },
                          okButton: "OK",
                          hideCancelButton: true,
                      },
                  })
                }
              }
              return throwError(() => error);
            })
          );
        } else {
          
          return next.handle(req);
        }
    }

}