import { HTTP_INTERCEPTORS, HttpClientJsonpModule, HttpClientModule } from "@angular/common/http";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AuthTokenInterceptor } from "@core/interceptors/auth-token.interceptor";
import { HttpErrorInterceptor } from "@core/interceptors/http-error.interceptor";
import { UserParamInterceptor } from "@core/interceptors/user-param.interceptor";
import { OrderErrorInterceptor } from "@core/interceptors/order-error.interceptor";
import { AuthApiService } from "@core/services/auth-api.service";
import { NgIdleKeepaliveModule } from "@ng-idle/keepalive";
import { NotificationService } from "@shared/services/notification.service";
import { EMPTY, catchError } from "rxjs";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { PortalLayoutModule } from "./portal/portal-layout/portal-layout.module";
import { GoogleMapsLoaderService } from "@core/services/google-maps-loader.service";
import { ForgotPasswordResolver } from "src/app/portal/forgot-password/forgot-password.resolver";

// Register French locales
import { registerLocaleData } from "@angular/common";
import localeFr from "@angular/common/locales/fr";

registerLocaleData(localeFr);

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        HttpClientJsonpModule,
        FormsModule,
        ReactiveFormsModule,
        PortalLayoutModule,
        NgIdleKeepaliveModule.forRoot(),
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: (authApiService: AuthApiService, notificationService: NotificationService) => () =>
                // If error appears in APP_INITIALIZER, angular stops loading of the application.
                // By this reason we should ignore the error.
                authApiService.getCurrentProfile().pipe(catchError(() => {
                    notificationService.custom({
                        duration: 0,
                        data: "Your session is expired",
                        panelClass: ["dui-notification", "warning"],
                    });
                    return EMPTY;
                })),
            deps: [AuthApiService, NotificationService],
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [GoogleMapsLoaderService],
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthTokenInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: UserParamInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: OrderErrorInterceptor,
            multi: true,
        },
        { provide: Window, useValue: window },
        ForgotPasswordResolver,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
